<template>
    <div class="pnf">
        <Lottie :options="defaultOptions"/>
    </div>
</template>

<script>
//import pnfanim1 from "../assets/404anim1.gif"
import * as animationData from "../assets/29894-error-404-page.json"
import Lottie from 'vue-lottie/src/lottie.vue'
export default {
    data() {
        return{
            defaultOptions: {animationData: animationData.default},
        }
    },
    mounted(){
    },
    components:{
        Lottie,
    }
}
</script>

<style lang="scss">
    .pnf{
        padding-right: 100px;
        padding-left: 100px;
        height: calc(100vh - 90px);
    }
</style>